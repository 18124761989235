import memonomics from "../assets/images/bnomics.png";


const Tokenomics = () => {

    return ( 
        <>
            <div className="tokenomics">
                <div className="wrapper_">
                    <div className="tokenomics-container">
                        <img src={memonomics} alt="" />
                        <div className="tokenomics-container-content">
                            <h2>BGOKENOMICS</h2>
                            <ul className="tokenomics-content">
                                <li><p>Total supply: 1,000,000,000 $BGOKE</p></li>
                                <li><p>Launch through Pump.fun</p></li>
                                <li><p>FAIR TOKENS DISTRIBUTION</p></li>
                                <li><p>10% TOKENS ON DEV WALLET (PROJECT DEVELOPMENT)</p></li>
                                <li><p>NO TEAM TOKENS</p></li>
                                <li><p>No Taxes</p></li>
                                <li><p>LP BURNED</p></li>
                                <li><p>CONTRACT REVOKED/UNMINTABLE/IMMUTABLE</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
}
 
export default Tokenomics;