import memos from "../assets/images/bgok.png";
import memos_text from "../assets/images/bgoke_text.png";
import telegram from "../assets/images/telegram.svg";
import x from "../assets/images/x.svg";
import tool from "../assets/images/tool.svg";
import buy from "../assets/images/buy.svg";
import noise from "../assets/images/noise.png";


const Header = () => {

    return ( 
        <>
            <header className="header">
                <div className="wrapper">
                    <div className="header-container">
                        <div className="header-container-content">
                            <div className="dogwifcash">
                                <h1><span>Bgoke</span> is a <br /> community <br /> movement</h1>
                                <div className="social-conteiner">
                                    <div className="social">
                                        <ul>
                                            <li><a href="https://t.me/bgoke"><img src={telegram} alt="" /></a></li>
                                            <li className="social-last-item"><a href="https://x.com/bgokesol"><img src={x} alt="" /></a></li>
                                        </ul>
                                        <div>
                                            <p>Powered by Solana</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-conteiner-buy">
                                    <a href="https://pump.fun/FTNB9nU7n6E5DPpUornNHjVaoSK54KN1hLusCFjppump" className="button" ><img src={buy} alt="" /></a>
                                </div>
                            </div>
                            <div className="dog">
                                <div className='twenty-conteiner'>
                                    <img className='dog-left' src={memos_text} alt="" />
                                    <img className='dog-right' src={memos} />
                                </div>
                            </div>
                            <div className="wallet-conteiner">
                                <div className="wallet">
                                    <h2>We are a community of degens <br /> who love memes, fun,<br /> and can laugh at ourselves <br /> <br /> Join the  <span>$BGOKE</span> army by <br /> adding a visor to your avatar <br /> or creating a meme with Bgoke</h2>
                                    <div className="button-conteiner">
                                        <a href="http://tool.bgoke.fun/" className="button" ><img src={tool} alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </header>
        </>
    );
}
 
export default Header;