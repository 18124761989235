import mem_two from "../assets/images/b_two.png";
import { useClipboard } from 'use-clipboard-copy';
import copy from "../assets/images/copy.svg";

const Preheader = () => {

    const clipboard = useClipboard({
        onSuccess() {
          console.log('Text was copied successfully!')
        },
        onError() {
          console.log('Failed to copy text!')
        }
      });

    return ( 
        <>
            <div className="tokenomics">
                <div className="wrapper">
                    <div className="copy">
                        <h2>CA:</h2>
                        <div className="copylabel"><input type="text" ref={clipboard.target} value="FTNB9nU7n6E5DPpUornNHjVaoSK54KN1hLusCFjppump"></input><button className="copybutton" onClick={clipboard.copy}><img className="copyicon" src={copy} alt="" /></button></div>
                    </div>
                </div>
                    <div className="marquee-container">
                        <div className="marquee">
                            <span>$BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE</span>
                            <span>$BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE </span>
                        </div>
                    </div>
                <div className="wrapper">
                <div className="content-cont-dog">
                            <div className="content-dog">
                                <p className="content"> 
                                    <span>Bgoke</span> is a legendary character <br /> — a once successful  
                                    stock trader <br /> who plunged into the world of crypto <br /> 
                                    to make his dream (Lambo) come true. <br /> Several scams
                                    and rugs cooked him so hard. <br /> The final blow was a 
                                    drainer that stole <br /> the last $87 from his wallet <br />
                                    Now, he's completely broke <br /> and working a 5/2 job 
                                    at BDonalds <br />
                                    <br />
                                    <span>The ticker is $BGOKE</span> <br />
                                    Located on Solana Blockchain <br />
                                    Community Takeover
                                </p>  
                            </div>
                            <div className="content-img-dog">
                                <img src={mem_two} alt="" />
                            </div>
                        </div>
                </div>
            </div>
        </>
    );
}
 
export default Preheader;