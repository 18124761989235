import airdrop from "../assets/images/airdrop.png";

const Content = () => {
    return ( 
        <> 
        <div className="airdrop">
                    <div className="marquee-container">
                        <div className="marquee">
                            <span>$BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE</span>
                            <span>$BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE $BGOKE </span>
                        </div>
                    </div>
            <div className="wrapper_">
                    <div className="airdrop-container">
                        <div className="airdrop-container-content">
                            <div className="airdrop-content">
                                <img src={airdrop} alt="" />
                                <div className="airdrop-content-item">
                                    <span className="content-color">HOW TO BUY $BGOKE</span>
                                    <p className="content-text">
                                        01. Create Wallet <br />
                                        <br />
                                        Download Phantom or your wallet of choice from <br />
                                        the app store or Google Play for free. Desktop users, <br /> 
                                        download the Google Chrome extension by going to Phantom. <br />
                                        <br />
                                        02. GET SOME SOL <br />
                                        <br />
                                        Have SOL in your wallet to switch to $BGOKE.<br />
                                        If you don’t have any SOL, you can buy directly on Phantom, <br />
                                        transfer from another wallet, <br />
                                        or buy on another exchange and send it to your wallet.<br />
                                        <br />
                                        03. GO TO JUPITER<br />
                                        <br />
                                        Connect to Jupiter. Connect your wallet in chrome. <br />
                                        Paste the $BGOKE token address, select $BGOKE, and confirm.<br />
                                        When Phantom prompts you for a wallet signature, sign.<br />
                                        <br />
                                        04. SWAP FOR BGOKE<br />
                                        <br />
                                        Switch SOL for $BGOKE. We have Zero taxes so you don’t need <br />
                                        to worry about buying with a specific slippage.
                                    </p>  
                                </div>
                            </div>
                        </div>
                 </div>
            </div>
        </div>
        </>
    );
}
 
export default Content;