import mempmap from "../assets/images/bmap.png";

const Roadmap = () => {
    return ( 
        <>
            <div  className="roadmap">
                <div className="wrapper">
                    <div className="roadmap-container">
                        <div className="roadmap-container-content">
                            <h2>BGOKEMAP</h2>
                            <ul className="roadmap-content">
                                <li className="roadmap-content-item-one">
                                    <span>Phase 1</span>
                                    <p>
                                        (Pump.fun): Memes,<br />
                                        Good vibes, Preparing <br />
                                        for Takeoff 
                                    </p>
                                </li>
                                <li className="roadmap-content-item-two">
                                    <span>Phase 2</span>
                                    <p>
                                        (Raydium, DEX): <br />
                                        Swap, Vibe, <br />
                                        HODL, Repeat. 
                                    </p>
                                </li>
                                <li className="roadmap-content-item-three">
                                    <span>Phase 3</span>
                                    <p>
                                        CEX listing on <br />
                                        major exchanges
                                    </p>
                                </li>
                                <li className="roadmap-content-item-fore">
                                    <span>Phase 4</span>
                                    <p>
                                        NFT collection<br />Mini-Game
                                    </p>
                                </li>
                                <li className="roadmap-content-item-five">
                                    <span>Phase 5</span>
                                    <p>
                                        Expanding <br />
                                        $BGOKE <br />
                                        Ecosystem
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <img src={mempmap} alt="" />
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Roadmap;